I<template>
  <div class="pa-3">
    <v-row justify="start">
      <v-dialog
        v-model="dialogUpdateIdentitas"
        persistent
        scrollable
        max-width="450px"
      >
        <v-card class="rounded-max hide_overflow" flat min-height="300px">
          <div class="closer d-flex justify-end pa-1">
            <v-btn icon small @click="$emit('close')">
              <v-icon small color="#000">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-container class="pa-0">
              <div>
                <div class="d-flex align-center mb-5">
                  <v-icon large color="#00b4cc"
                    >mdi-calendar-month-outline</v-icon
                  >
                  <p class="title-tanggal-modal">
                    UPDATE IDENTITAS
                  </p>
                </div>
                <div class="form-group">
                  <!-- KODE PUSKESMAS / RUMAH SAKIT -->
                  <v-text-field
                    v-if="identity && identity.tipe_fasyankes == '1'"
                    v-model="identity.kode_rs"
                    label="Kode Rumah Sakit"
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-if="identity && identity.tipe_fasyankes == '2'"
                    v-model="identity.kodePuskesmas"
                    label="Kode Puskesmas"
                    outlined
                  ></v-text-field>
                  <!-- NAMA FASYANKES -->
                  <v-text-field
                    v-if="identity"
                    v-model="identity.nama_instansi"
                    label="Nama Fasyankes"
                    outlined></v-text-field>
                  <!-- NAMA LOKASI -->
                  <v-text-field
                    v-if="identity"
                    v-model="identity.longlat"
                    label="Lokasi"
                    outlined></v-text-field>
                  <!-- NAMA PROVINSI -->
                  <v-select
                    placeholder="Provinsi"
                    item-text="provinsi_name"
                    item-value="provinsi_id"
                    v-model="selectedProvince"
                    @change="setProvince"
                    :items="listProvince"
                    hide-details="auto"
                    outlined
                  ></v-select>
                  <!-- NAMA KABUPATEN -->
                  <v-select
                    placeholder="Kabupaten/Kota"
                    item-text="kabupaten_name"
                    item-value="kabupatenKota_id"
                    v-model="selectedCity"
                    @change="setKabupaten"
                    class="mt-5"
                    :items="listCity"
                    hide-details="auto"
                    outlined
                  ></v-select>
                  <!-- NAMA KECAMATAN -->
                  <v-select
                    placeholder="Kecamatan"
                    item-text="kecamatan_name"
                    item-value="kecamatan_id"
                    v-model="selectedKecamatan"
                    class="mt-5"
                    :items="listKecamatan"
                    hide-details="auto"
                    outlined
                  ></v-select>
                </div>
                <div class="form-group">
                  <div class="d-flex pb-3 mt-4">
                    <!-- @click="createForm" -->
                    <v-btn
                      @click="save"
                      class="text-capitalize py-6"
                      color="#00b4cc"
                      depressed
                      dark
                      block
                      ><b>Save</b></v-btn
                    >
                  </div>
                </div>
              </div>
              <v-overlay
                :absolute="true"
                color="white"
                opacity="1"
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  color="#00b4cc"
                ></v-progress-circular>
                <b class="color_default">Loading...</b>
              </v-overlay>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "updateIndentitas",

  props: {
    dialogUpdateIdentitas: {
      type: Boolean,
    },
    identity: {
      type: Object,
      default: () => ({
        tipe_fasyankes: null,
        nama_instansi: '',
      }),
    },
  },
  data() {
    return {
      loading: false,
      text: null,
      selected: null,
      listProvince: [],
      listCity: [],
      listKecamatan: [],
    };
  },
  computed: {
    selectedProvince: {
      get() {
        let data = null;
        if (this.identity && this.identity.provinsi_id) {
          data = this.identity.provinsi_id;
        }
        return data;
      },
      set(value) {
        this.identity.provinsi_id = value;
      }
    },
    selectedCity: {
      get() {
        let data = null;
        if (this.identity && this.identity.kabupatenKota_id) {
          this.getCity();
          data = this.identity.kabupatenKota_id;
        }
        return data;
      },
      set(value) {
        this.identity.kabupatenKota_id = value;
      }
    },
    selectedKecamatan: {
      get() {
        let data = null;
        if (this.identity && this.identity.kecamatan_id) {
          this.getKecamatan();
          data = this.identity.kecamatan_id;
        }
        return data;
      },
      set(value) {
        this.identity.kecamatan_id = value;
      }
    },
  },
  mounted() {
    this.getProvince();
  },
  methods: {
    getProvince() {
      this.$store.dispatch("region/getProvince").then((data) => {
        this.listProvince = data.data;
      });
    },
    getCity() {
      let data = {
        provinsi_id: this.identity.provinsi_id,
      };
      this.$store.dispatch("region/getCity", data).then((data) => {
        this.listCity = data.data;
      });
    },
    getKecamatan() {
      let data = {
        kabupatenKota_id: this.identity.kabupatenKota_id,
      };
      this.$store.dispatch("region/getKecamatan", data).then((data) => {
        this.listKecamatan = data.data;
      });
    },
    setProvince() {
      this.listCity = [];
      this.listKecamatan = [];
      this.selectedCity = null;
      this.identity.kabupatenKota_id = null;
      this.selectedKecamatan = null;
      this.identity.kecamatan_id = null;
      this.getCity();
    },
    setKabupaten() {
      this.selectedKecamatan = null;
      this.identity.kecamatan_id = null;
      this.getKecamatan();
    },
    save() {
      this.loading = true;
      this.$store
        .dispatch("user/updateIdentitas", this.identity)
        .then((data) => {
          this.field = 0;
          this.change = false;
          this.loading = false;
          this.Swal("success", data.message);
          this.$emit("getProfile");
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });

    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: "Data Berhasil Diubah",
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.temp_day {
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}
.aday {
  width: calc(100% / 7);
  cursor: pointer;
}
.bordered {
  border: 1px solid #d7d7d7 !important;
}
.bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.color_txt {
  color: #7f7f7f;
}
.small_txt {
  font-size: small;
}

.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 25%;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 25%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
</style>