<template>
  <v-row>
    
    <v-col v-if="$isMobile()" class="d-flex justify-center mx-auto" cols="7" md="5">
      <v-card class="card-rs">
        <img :src="photo_profile" alt="" />
      </v-card>
    </v-col>

    <v-col v-else cols="5" md="4">
      <v-card class="card-rs">
        <img :src="photo_profile" alt="" />
      </v-card>
    </v-col>
   
    <v-col cols="12" md="7">
      <v-row >
        <v-col cols="12" md="6">
          <v-card class="rounded-max">
            <div class="title-form-card">
              <h5 style="margin-left: 6px">
                Kode
                {{
                  identity.tipe_fasyankes == "1"
                    ? "Rumah Sakit"
                    : "Puskesmas"
                }}
              </h5>
            </div>
            <div class="value-form-card">
              <div class="cardTextContent">
                {{
                  identity.tipe_fasyankes == "1"
                    ? identity.kode_rs
                      ? identity.kode_rs
                      : "-"
                    : identity.kodePuskesmas
                    ? identity.kodePuskesmas
                    : "-"
                }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-max">
            <div class="title-form-card">
              <h5 style="margin-left: 6px">Nama Fasyankes</h5>
            </div>
            <div class="value-form-card">
              <div class="cardTextContent">
                {{
                  identity.nama_instansi ? identity.nama_instansi : "-"
                }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-max">
            <div class="title-form-card">
              <h5 style="margin-left: 6px">Lokasi</h5>
            </div>
            <div class="value-form-card">
              <div class="cardTextContent">
                {{ identity.longlat ? identity.longlat : "-" }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-max">
            <div class="title-form-card">
              <h5 style="margin-left: 6px">Provinsi</h5>
            </div>
            <div class="value-form-card">
              <div class="cardTextContent">
                {{
                  identity.provinsi_name ? identity.provinsi_name : "-"
                }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-max">
            <div class="title-form-card">
              <h5 style="margin-left: 6px">Kabupaten/Kota</h5>
            </div>
            <div class="value-form-card">
              <div class="cardTextContent">
                {{
                  identity.kabupaten_name
                    ? identity.kabupaten_name
                    : "-"
                }}
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-max">
            <div class="title-form-card">
              <h5 style="margin-left: 6px">Kecamatan</h5>
            </div>
            <div class="value-form-card">
              <div class="cardTextContent">
                {{
                  identity.kecamatan_name ? identity.kecamatan_name : ""
                }}
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="$isMobile()" class="d-flex justify-center">
        <v-btn
          rounded
          color="#00b4cc"
          dark
          class="text-capitalize mt-5"
          @click.stop="$emit('showUpdateModal')"
        >
          Update Identitas
        </v-btn>
      </div>

      <div v-else class="d-flex justify-end">
        <v-btn
          rounded
          color="#00b4cc"
          dark
          class="text-capitalize mt-5"
          @click.stop="$emit('showUpdateModal')"
        >
          Update Identitas
        </v-btn>
      </div>

      
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: "TabFormIdentity",
    props: {
        identity: {
            type: Object,
            default: () => {}
        },
        photo_profile: {
            type: String,
            default: null
        },
    },
    data(){
      return {
        
      }
    },
    methods: {
    
  },
  mounted() {
    
    
  },
}
</script>

<style scoped>
* {
  font-family: 'Montserrat', sans-serif;
}
.cardTextContent{
    color: black; 
    font-weight: 600; 
    font-size: 14px; 
    margin-top: 8px; 
    margin-bottom: 8px; 
    margin-left: 8px;
  }

@media screen and (max-width: 600px){
  .cardTextContent{
    color: black; 
    font-weight: 600; 
    font-size: 14px; 
    margin-top: 8px; 
    margin-bottom: 8px; 
    margin-left: 8px;
  }
  
}
</style>