<template>
  <v-row>
    <v-col v-if="$isMobile()" class="d-flex justify-center mx-auto" cols="7" md="5">
      <img
        src="../../../../assets/img/bgprofileformulir.svg"
        height="150px"
        alt=""
      />
    </v-col>

    <v-col v-else cols="5" md="4">
      <img
        src="../../../../assets/img/bgprofileformulir.svg"
        height="250px"
        alt=""
      />
    </v-col>

    <v-col cols="12" md="8">
      <v-row v-if="identity.tipe_fasyankes == FASYANKES_TYPE.Hospital">
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/icon-kepemilikan.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <div class="textProfile">Kepemilikan</div>
                  <h6 style="font-weight: 900;">
                    {{ identity.kepemilikan ? identity.kepemilikan : "-" }}
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/kelas-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <div class="textProfile">Kelas</div>
                  <h6 style="font-weight: 900;">
                    {{ identity.kelas ? identity.kelas : "-" }}
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/bor-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <div class="textProfile">BOR (%)</div>
                  <h6 style="font-weight: 900;">
                    {{ identity.persenBOR ? identity.persenBOR : "-" }}
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img
                  src="../../../../assets/img/karakteristik-icon.svg"
                  alt=""
                />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <div class="textProfile">Karakteristik Wilayah</div>
                  <h6 style="font-weight: 900;">
                    {{
                      identity.karakteristikWilayah
                        ? identity.karakteristikWilayah
                        : "-"
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" flat md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/tidur-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <div class="textProfile">Tempat Tidur (unit)</div>
                  <h6 style="font-weight: 900;">
                    {{
                      identity.jumlahTempatTidur
                        ? identity.jumlahTempatTidur
                        : "-"
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/timbulan-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <div class="textProfile">Timbulan Limbah(kg/pasien/hari)</div>
                  <h6 style="font-weight: 900;">
                    {{
                      identity.timbunanLimbah ? identity.timbunanLimbah : "-"
                    }}
                    
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="identity.tipe_fasyankes == FASYANKES_TYPE.PublicHealthCenter">
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/kelas-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <p>Non Rawat Inap (Rata-rata jumlah pasien)</p>
                  <div class="d-flex align-center">
                    <h6>
                      {{
                        identity.rataJumlahPasien
                          ? identity.rataJumlahPasien
                          : "-"
                      }}
                    </h6>
                    <p class="ma-0 small_txt ml-1 mr-2">orang/hari</p>
                    <p class="ma-0 small_txt">
                      {{ $date(identity.update_at).format("MMMM YYYY") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" flat md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/tidur-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <p>Tempat Tidur (unit)</p>
                  <div class="d-flex align-center">
                    <h6>
                      {{
                        identity.jumlahTempatTidur
                          ? identity.jumlahTempatTidur
                          : "-"
                      }}
                    </h6>
                    <b class="small_txt ml-1"></b>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/bor-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <p>Rawat Inap - BOR (Bed Occupancy Rate)</p>
                  <div class="d-flex align-center">
                    <h6>
                      {{ identity.persenBOR ? identity.persenBOR : "-" }}
                    </h6>
                    <p class="ma-0 small_txt mr-2">%</p>
                    <p class="ma-0 small_txt">
                      {{ $date(identity.update_at).format("MMMM YYYY") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img
                  src="../../../../assets/img/karakteristik-icon.svg"
                  alt=""
                />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <p>Karakteristik Wilayah</p>
                  <h6>
                    {{
                      identity.karakteristikWilayah
                        ? identity.karakteristikWilayah
                        : "-"
                    }}
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="rounded-lg" flat style="overflow: hidden">
            <div class="d-flex">
              <div class="icon-left-rs">
                <img src="../../../../assets/img/timbulan-icon.svg" alt="" />
              </div>
              <div class="value-form d-flex w-100">
                <div class="value-profile-formulir">
                  <p class="ma-0 small_txt color_txt">Timbulan limbah</p>
                  <h6>
                    {{
                      identity.timbunanLimbah ? identity.timbunanLimbah : "-"
                    }}
                    <small>(kg/pasien/hari)</small>
                  </h6>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <div v-if="$isMobile()" class="d-flex justify-center">
        <v-btn
          rounded
          color="#00b4cc"
          dark
          class="text-capitalize mt-5"
          @click.stop="$emit('showUpdateModal')"
        >
          Update Profile
        </v-btn>
      </div>

      <div v-else class="d-flex justify-end">
        <v-btn
          rounded
          color="#00b4cc"
          dark
          class="text-capitalize mt-5"
          @click.stop="$emit('showUpdateModal')"
        >
          Update Profile
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { FASYANKES_TYPE } from '../../../../constants/constant'

export default {
  name: "TabFormProfile",
  props: {
    identity: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    FASYANKES_TYPE,
    
  }),
  mounted() {
    
  },
  methods: {
    
  }
};
</script>

<style scoped>
.textProfile {
  font-weight: 400; 
  font-size: 13px;
}

</style>