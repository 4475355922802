<template>
  <div>
    
    <div id="formulirContainer" class="padd_content">
      <div v-if="$isMobile()" class="content">
        
        <div class="title-top">
          <div class="card-icon">
            <img src="../../assets/img/icon-rs.svg" alt="" />
          </div>
          <h4 style="color: #31B4CC;">{{ profile.nama_instansi }}</h4>
        </div>

        <template>
          
          <v-tabs
            class="custom-tabs"
            v-model="tab"
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="rgba(255, 255, 255, 0)"></v-tabs-slider>
            <v-tab class="custom-tab" v-for="item in items" :key="item">
              <div >{{ item }}</div>
            </v-tab>
          </v-tabs>
        </template>

        <v-tabs-items
          v-model="tab"
          style="background-color: none"
          class="mt-5"
          v-if="identity"
        > 
          <!-- IDENTITAS -->
          <v-tab-item> 
            <v-card
              class="custom_card rounded-max card-form"
              min-height="200px"
              
            >
              <TabFormIdentity
                :identity="identity"
                :photo_profile="photo_profile"
                @showUpdateModal="dialogUpdateIdentitas = true"
              />
            </v-card>
          </v-tab-item>
          <!-- PROFIL -->
          <v-tab-item>
            <v-card
              class="custom_card rounded-max card-form"
              min-height="200px"
            >
              <TabFormProfile
                :identity="identity"
                @showUpdateModal="dialogUpdateProfile = true"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <TabFormForm 
              :user_type="profile.usertype"
              @showKeslingKunci="dialogKunci = true"
              @showCreateFasyankes="dialogCreateFasyankes = true"
              @showLimbahCovid="dialogAssignCovid = true"
              @showAlkesBermerkuri="dialogAlkesBermerkuri = true"
              @showKeslingPlus="dialogCreateKesling = true"
              @showSanitarianKit="dialogCreateSanitarian = true"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

      <div v-else class="content">

        <div>
          <modal-notif
            v-bind:dialogNotif="dialogNotif"
            @close="dialogNotif = false"
            />
        </div>

        <div class="title-top">
          <div class="card-icon">
            <img src="../../assets/img/icon-rs.svg" alt="" />
          </div>
          <h5>{{ profile.nama_instansi }}</h5>
        </div>
        <template>
          <v-tabs
            style="margin: 0"
            v-model="tab"
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="rgba(255, 255, 255, 0)"></v-tabs-slider>
            <v-tab v-for="item in items" :key="item">
              <div class="tab-title">{{ item }}</div>
            </v-tab>
          </v-tabs>
        </template>

        <v-tabs-items
          v-model="tab"
          style="background-color: none"
          class="mt-5"
          v-if="identity"
        > 
          <!-- IDENTITAS -->
          <v-tab-item> 
            <v-card
              class="custom_card rounded-max card-form"
              min-height="200px"
            >
              <TabFormIdentity
                :identity="identity"
                :photo_profile="photo_profile"
                @showUpdateModal="dialogUpdateIdentitas = true"
              />
            </v-card>
          </v-tab-item>
          <!-- PROFIL -->
          <v-tab-item>
            <v-card
              class="custom_card rounded-max card-form"
              min-height="200px"
            >
              <TabFormProfile
                :identity="identity"
                @showUpdateModal="dialogUpdateProfile = true"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <TabFormForm 
              :user_type="profile.usertype"
              @showKeslingKunci="dialogKunci = true"
              @showCreateFasyankes="dialogCreateFasyankes = true"
              @showLimbahCovid="dialogAssignCovid = true"
              @showAlkesBermerkuri="dialogAlkesBermerkuri = true"
              @showKeslingPlus="dialogCreateKesling = true"
              @showSanitarianKit="dialogCreateSanitarian = true"
            />
          </v-tab-item>
        </v-tabs-items>
      </div>

    </div>

    <v-dialog v-model="dialog" max-width="450">
      <v-card class="pb-3">
        <div class="d-flex justify-end">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div style="padding: 0 60px">
          <div class="d-flex align-center mb-5">
            <img src="../../assets/img/icon-calendar.svg" alt="" />
            <p class="title-tanggal-modal">Agustus 2021</p>
          </div>
          <div class="form-group">
            <v-select
              :items="itemsSelect"
              label="Choose Year"
              outlined
            ></v-select>
            <div class="mb-4">
              <v-btn-toggle
                v-model="text"
                tile
                color="deep-purple accent-3"
                group
              >
                <v-row class="mx-0">
                  <v-col
                    cols="4"
                    style="padding: 5px !important"
                    v-for="item in bulan"
                    :key="item.id"
                  >
                    <v-btn-toggle class="w-100" v-model="pilihBulan">
                      <v-btn
                        class="w-100 btn-bulan"
                        outlined
                        height="40"
                        :value="item.value"
                      >
                        <div
                          v-if="item.status == 1"
                          class="round-info red me-2"
                        ></div>
                        <div
                          v-if="item.status == 2"
                          class="round-info yellow me-2"
                        ></div>
                        {{ item.name }}
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-btn-toggle>
            </div>
            <div class="px-2 mt-7">
              <div class="d-flex align-center legend">
                <div class="round-info bg-sucs me-2"></div>
                <p>Formulir Telah Terkirim dan Terkonfirmasi</p>
              </div>
              <div class="d-flex align-center legend">
                <div class="round-info bg-perbaikan me-2"></div>
                <p>Formulir Perlu Perbaikan</p>
              </div>
              <div class="d-flex align-center legend">
                <div class="round-info bg-terkirim me-2"></div>
                <p>Formulir Telah Terkirim</p>
              </div>
              <div class="d-flex align-center legend">
                <div class="round-info bg-belum me-2"></div>
                <p>Formulir Belum Terkirim</p>
              </div>
              <div class="d-flex align-center legend">
                <div class="round-info red me-2"></div>
                <p>Formulir Tidak Terisi</p>
              </div>
            </div>
            <div class="w-100 mt-4">
              <v-btn
                rounded
                color="#00b4cc"
                dark
                class="text-capitalize w-100"
                @click="getReportId"
              >
                Lanjutkan Isi Formulir
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    

    <CreateLimbahFasyankes
      v-bind:dialogCreateFasyankes="dialogCreateFasyankes"
      @close="dialogCreateFasyankes = false"
    />
    <Covid19
      v-bind:dialogAssignCovid="dialogAssignCovid"
      @close="dialogAssignCovid = false"
    />
    <TypeKesling
      v-bind:dialogTypeKesling="dialogTypeKesling"
      @close="dialogTypeKesling = false"
      @pick="showKesling"
    />
    <CreateKesling
      v-bind:dialogCreateKesling="dialogCreateKesling"
      @close="dialogCreateKesling = false"
    />
    <create-kunci
      v-bind:dialogKunci="dialogKunci"
      @close="dialogKunci = false"
    />
    <CreateSanitarianKit
      v-bind:dialogCreateSanitarian="dialogCreateSanitarian"
      @close="dialogCreateSanitarian = false"
    />
    <CreateAlkesBermerkuri
      v-bind:dialogAlkesBermerkuri="dialogAlkesBermerkuri"
      @close="dialogAlkesBermerkuri = false"
    />
    <updateIndentitas
      v-bind:dialogUpdateIdentitas="dialogUpdateIdentitas"
      v-bind:identity="identity"
      @close="dialogUpdateIdentitas = false"
      @getProfile="getProfile"
    />
    <updateProfile
      v-bind:dialogUpdateProfile="dialogUpdateProfile"
      v-bind:identity="identity"
      @close="dialogUpdateProfile = false"
      @getProfile="getProfile"
    />
  </div>
</template>


<script>
import Covid19 from "../../components/Formulir/Modal/createLimbahCovid.vue";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import TypeKesling from "../../components/Formulir/Modal/Kesling/alert.vue";
import CreateKesling from "../../components/Formulir/Modal/Kesling/createKesling.vue";
import CreateLimbahFasyankes from "../../components/Formulir/Modal/createLimbahFasyankes.vue";
import CreateSanitarianKit from "../../components/Formulir/Modal/createSanitarianKit.vue";
import CreateKunci from "../../components/Formulir/Modal/createKunci.vue";
import CreateAlkesBermerkuri from "../../components/Formulir/Modal/createAlkesBermerkuri.vue";
import updateIndentitas from "../../components/Formulir/Modal/updateIndentitas.vue";
import updateProfile from "../../components/Formulir/Modal/updateProfile.vue";

// Modal Notif
import modalNotif from "../../views/Notification/modalNotif.vue"

// Form Tabs
import TabFormIdentity from "./Tab/Form/Identity.vue"; // Identitas
import TabFormProfile from "./Tab/Form/Profile.vue"; // Profile
import TabFormForm from "./Tab/Form/Form.vue"; // Form

export default {
  name: "FormulirMainPage",
  computed: {
    ...mapState({
      role: (state) => state.role,
      profile: (state) => state.user.profile,
      photo_profile: (state) => state.imgDummy,
    }),
  },
  components: {
    Covid19,
    TypeKesling,
    CreateKesling,
    CreateLimbahFasyankes,
    CreateSanitarianKit,
    CreateKunci,
    CreateAlkesBermerkuri,
    updateIndentitas,
    updateProfile,
    TabFormIdentity,
    TabFormProfile,
    TabFormForm,
    modalNotif,
    
  },
  data() {
    return {
      dialogNotif: false,
      identity: {},
      pilihBulan: new Date().getMonth(),
      bulan: [
        {
          name: "Januari",
          value: 1,
          status: 1,
        },
        {
          name: "Februari",
          value: 2,
          status: 1,
        },
        {
          name: "Maret",
          value: 3,
          status: 1,
        },
        {
          name: "April",
          value: 4,
          status: 1,
        },
        {
          name: "Mei",
          value: 5,
          status: 1,
        },
        {
          name: "Juni",
          value: 6,
          status: 1,
        },
        {
          name: "Juli",
          value: 7,
          status: 1,
        },
        {
          name: "Agustus",
          value: 8,
          status: 1,
        },
        {
          name: "September",
          value: 9,
          status: 1,
        },
        {
          name: "Oktober",
          value: 10,
          status: 1,
        },
        {
          name: "November",
          value: 11,
          status: 1,
        },
        {
          name: "Desember",
          value: 12,
          status: 1,
        },
      ],
      text: null,
      itemsSelect: ["2021", "2022", "2023", "2024"],
      dialog: false,
      tab: null,
      items: ["Identitas", "Profil", "Formulir"],
      dialogAssignCovid: false,
      dialogTypeKesling: false,
      dialogCreateKesling: false,
      dialogCreateFasyankes: false,
      dialogCreateSanitarian: false,
      dialogAlkesBermerkuri: false,
      dialogUpdateIdentitas: false,
      dialogUpdateProfile: false,
      dialogKunci: false,
      typeKesling: "",
    };
  },
  methods: {
    getReportId() {
      // this.$router.push("/formulir/fasyankes");
      this.$store.dispatch("postApi", {
        url: "api/limbahfasyankes",
        mutation: "GET_REPORT_ID",
        data: {
          bulan: 10,
        },
        headers: {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            // "X-CSRF-TOKEN": `Bearer ${Cookies.get("token")}`,
          },
        },
      });
      // .then((res) => {
      //   if (res.data.message) {
      //     this.$router.push("/formulir/fasyankes");
      //   }
      // });
    },
    async getProfile() {
      this.$store.dispatch("formulir/getIdentitas").then((res) => {
        this.identity = res.data.data;

      });
    },
    getListBulan() {
      let newDate = new Date();
      let days = newDate.getDate();
      let month = newDate.getMonth();
      let year = newDate.getFullYear();
      let fullDate = days + "-" + month + "-" + year;
      this.$store.dispatch("postApi", {
        url: `api/limbahfasyankes/listbulan`,
        mutation: "GET_MONTHS",
        headers: {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
            withCredentials: true,
          },
        },
        data: {
          date: fullDate,
        },
      });
    },

    showKesling(type) {
      console.log(type);
      this.dialogTypeKesling = false;
      if (type == "a") {
        this.dialogKunci = true;
      } else {
        this.dialogCreateKesling = true;
      }
      this.typeKesling = type;
    },
  },
  mounted() {
    this.getProfile();
    this.getListBulan();

    if (this.$route.name == "Formulir") {
      if (this.role == "Fasyankes") {
        this.dialogNotif = true;
        
      }
    }
  },
};
</script>

<style scoped>
* {
  font-family: 'Montserrat', sans-serif;
}

.theme--light.v-tabs-items {
  background: none !important;
}

.padd_content {
  padding: 40px 50px;
}

.tab-title {
  font-weight: 600;
  padding-left: 20px;
  padding-right: 20px;
}


@media screen and (max-width: 600px){
  .custom-tab{
    font-size: 12px;
    display: flex !important;
    color: transparent;
    
  }
  .custom-tab div{
    font-size: 13px;
    display: flex !important;
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    color: grey;
  }
  .v-tab {
    padding: 0px;
  }
  
  .v-tab--active div{
    color: black;
  }
  .custom-tabs{
    margin-left: -40px;
  }
  .tab-title {
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    color: black;

  }

  .custom-tab .v-tab {
    font-weight: 700;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: capitalize;
    color: orange;
    
  }

}

</style>