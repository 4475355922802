<template>
  <div class="pa-3">
    <v-row justify="start">
      <v-dialog
        v-model="dialogAlkesBermerkuri"
        persistent
        scrollable
        max-width="450px"
      >
        <v-card class="rounded-max hide_overflow" flat min-height="300px">
          <div class="closer d-flex justify-end pa-1">
            <v-btn icon small @click="$emit('close')">
              <v-icon small color="#000">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-container class="pa-0">
              <div>
                <div class="d-flex align-center mb-5">
                  <v-icon large color="#00b4cc"
                    >mdi-calendar-month-outline</v-icon
                  >
                  <p class="title-tanggal-modal">
                    {{ $date().format("MMMM YYYY") }}
                  </p>
                </div>
                <div class="form-group">
                  <v-select
                    :items="getYear"
                    item-text="name"
                    item-value="idx"
                    v-model="year"
                    label="Choose Year"
                    disabled
                    outlined
                  ></v-select>
                  <div class="mb-4">
                    <v-btn-toggle
                      v-model="text"
                      tile
                      color="deep-purple accent-3"
                      group
                    >
                      <v-row class="mx-0">
                        <v-col
                          cols="4"
                          style="padding: 5px !important"
                          v-for="(item, idx) in monthInfo"
                          :key="idx"
                        >
                          <v-btn
                            class="w-100 btn-bulan d-flex justify-start"
                            outlined
                            :disabled="
                              item.month != $date().format('M') ||
                              item.report_status_code == '1' ||
                              item.report_status_code == '2'
                            "
                            @click="selected = item"
                            height="40"
                            :value="item"
                          >
                            <div
                              v-if="item.month <= $date().format('M')"
                              :class="
                                item.report_status_code == null
                                  ? 'nofill'
                                  : item.report_status_code == 0
                                  ? 'unsend'
                                  : item.report_status_code == 1
                                  ? 'sent'
                                  : item.report_status_code == 2
                                  ? 'confirm'
                                  : 'repair'
                              "
                            ></div>
                            {{ item.month_name }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-btn-toggle>
                  </div>
                  <div class="px-2 mt-7">
                    <div class="py-3">
                      <div class="d-flex align-center">
                        <div class="bullet sent mr-2"></div>
                        <div class="">
                          <p class="ma-0 small_txt color_txt">
                            Formulir Telah Terkirim
                          </p>
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <div class="bullet unsend mr-2"></div>
                        <div class="">
                          <p class="ma-0 small_txt color_txt">
                            Formulir Belum Terkirim
                          </p>
                        </div>
                      </div>
                      <div class="d-flex align-center">
                        <div class="bullet nofill mr-2"></div>
                        <div class="">
                          <p class="ma-0 small_txt color_txt">
                            Formulir Tidak Terisi
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex pb-3 mt-4">
                    <v-btn
                      @click="createForm"
                      class="text-capitalize py-6"
                      color="#00b4cc"
                      depressed
                      dark
                      block
                      ><b>Lanjutkan Isi Formulir</b></v-btn
                    >
                  </div>
                </div>
              </div>
              <v-overlay
                :absolute="true"
                color="white"
                opacity="1"
                :value="loading"
              >
                <v-progress-circular
                  indeterminate
                  class="mr-2"
                  color="#00b4cc"
                ></v-progress-circular>
                <b class="color_default">Loading...</b>
              </v-overlay>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "createAlkesBermerkuri",
  props: ["dialogAlkesBermerkuri"],
  computed: {
    getYear() {
      let data = [];
      let year = parseInt(this.$date().format("YYYY"));
      for (let i = 2020; i <= year; i++) {
        data.push({
          name: i,
          idx: i,
        });
      }
      return data;
    },
  },
  data() {
    return {
      step: 1,
      role: "",
      monthInfo: null,
      year: new Date().getFullYear(),
      loading: false,
      month: parseInt(this.$date().format("M")),
      year: parseInt(this.$date().format("YYYY")),
      day: null,
      dateNow: parseInt(this.$date().format("D")),
      selectedDate: null,
      bulan: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
      text: null,
      items: ["Identitas", "Profil", "Pengisian Formulir"],
      selected: null,
    };
  },
  mounted() {
    this.getListMonth();
  },
  methods: {
    getListMonth() {
      this.loading = true;
      let body = {
        date: this.$date().format("YYYY-MM-DD"),
      };
      this.$store.dispatch("formulir/listMonthAlkesBermerkuri", body).then((data) => {
        this.monthInfo = data;
        this.loading = false;
      });
    },
    createForm() {
      if (
        this.selected.report_status_code == null
      ) {
        let date = this.$date().format("YYYY-MM-DD");
        // this.$router.push(`/formulir/limbah-fasyankes/redaksi/${date}`);
        this.$router.push(`/formulir/alkes-merkuri`);
      }
    },
  },
};
</script>

<style scoped>
.temp_day {
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}
.aday {
  width: calc(100% / 7);
  cursor: pointer;
}
.bordered {
  border: 1px solid #d7d7d7 !important;
}
.bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.color_txt {
  color: #7f7f7f;
}
.small_txt {
  font-size: small;
}

.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}
.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 25%;
  border-radius: 10px 10px 0 0;
}
.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 25%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}
.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}
.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}
.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}
.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}
.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}
.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}
.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
</style>