<template>
  <v-row justify="start">

    <v-dialog v-if="$isMobile()" v-model="dialogNotif" persistent width="360px" content-class="elevation-0 overflow-y-hidden" >
          <v-card class="more_rounded hide_overflow opc_" height="auto" flat>
            <div class="absolute_component">
              <img
                src="../../assets/img/wave_from2.svg"
                class="lyr_tp"
                style="width: 100%"
                alt=""
              />
              <img
                src="../../assets/img/wave_from1.svg"
                style="width: 100%"
                alt=""
              />
              <div
                class="d-flex justify-center flex-column pa-5 lyr_tp"
                style="width: 100%"
              >
                <div class="d-flex justify-center "></div>
                <p style="font-size: 18px; font-weight: 600;" class="white--text text-center">Jadwal Pengisian <br> Formulir</p>
              </div>
              <div
                class="closer d-flex justify-end pa-1 lyr_tp"
                style="width: 100%"
              >
                
              </div>
            </div>
            <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
              <v-progress-circular
                indeterminate
                class="mr-2"
                color="#00b4cc"
              ></v-progress-circular>
              <b class="color_default">Loading...</b>
            </v-overlay>
            
            <div class="mt-5 px-5 py-5" v-if="!loading && data.length >= 1">
              <v-card
                class="rounded-lg pa-3 mb-3"
                v-for="(item, idx) in filteredData"
                :key="idx"
                flat
                color="#FBFBFB"
              >
                <div v-if="item.tipeNotif === 'notif'">
                  <div class="d-flex flex-column">
                    <v-col cols="6">
                      <div class="d-flex align-center h_percent">
                        <v-icon color="#00b4cc" class="mr-2" v-if="!item.read_at"
                          >mdi-alert-decagram</v-icon
                        >
                        <p class="ma-0 pa-0 small_txt">
                          {{ item.data.title }}
                        </p>
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <div class="d-flex align-center h_percent">
                        <p class="ma-0 pa-0 small_txt color_txt">
                          {{ $date(item.data.created_at).format("D MMMM YYYY") }}
                        </p>
                      </div>
                    </v-col>
                  </div>

                  <v-col cols="3">
                    <v-btn
                      class="text-capitalize"
                      small
                      dark
                      depressed
                      color="#00b4cc"
                      rounded
                      @click="toRedirect(item)"
                      v-if="
                        (item.data.data &&
                          item.data.data.notif_type === 'new-user' &&
                          item.related_model.isActive === 'NonActive') ||
                        (item.data.data &&
                          item.data.data.notif_type === 'confirm-form' &&
                          item.related_model.isActive !== 2)
                      "
                    >
                      {{
                        item.data.data.notif_type === "new-user"
                          ? "Aktivasi"
                          : "Konfirmasi"
                      }}
                    </v-btn>
                    <p
                      class="color_default"
                      v-if="
                        item.data.data &&
                        item.data.data.notif_type === 'confirm-form' &&
                        item.related_model.isActive === 2
                      "
                    >
                      Telah dikonfirmas
                    </p>
                  </v-col>
                </div>

                <div class="d-flex flex-row" v-else>
                  <div class="d-flex flex-column">
                    <div>
                      <div class="d-flex align-center h_percent">
                        
                        <p
                          style="font-size: 12px; color: black; font-weight: 600"
                          class="ma-0 pa-0"
                        >
                          {{ item.title }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div class="d-flex align-center h_percent">
                        <p style="font-size: 10px" class="ma-0 pa-0 color_txt">
                          {{ item.period }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex align-center px-2">
                    <v-btn
                      class="text-capitalize"
                      small
                      dark
                      depressed
                      color="#00b4cc"
                      @click="toRedirect(item)"
                      rounded
                    >
                      Isi Formulir
                    </v-btn>
                  </div>
                </div>
              </v-card>
              <div class="d-flex justify-center mt-1">
                <v-btn
                  style="border-radius: 20px"
                  dark
                  depressed
                  color="#00b4cc"
                  class="text-capitalize"
                  v-if="data.length >= 1"
                  >
                  <router-link
                    to="/user/notification"
                    style="color: white; text-decoration: none"
                  >
                    Lihat selengkapnya
                  </router-link>
                </v-btn>
              </div>
            </div>

            <div class="mt-5 px-5" v-if="!loading && data.length <= 0">
              <div class="d-flex justify-center mt-1">
                <b>Tidak Ada Notifikasi</b>
              </div>
            </div>
          </v-card>
            
          <v-card class="rounded-max transparent-card mt-6" height="100px">
          <v-btn class="btnClose" @click="$emit('close')">
            <v-icon style="font-size: 40px">mdi-close</v-icon>
          </v-btn>
        </v-card>
        
        
    </v-dialog>
      
    
    <v-dialog v-else v-model="dialogNotif" persistent max-width="800px">
      <v-card class="more_rounded hide_overflow opc_" height="650px" flat>
        <div class="absolute_component">
          <img
            src="../../assets/img/wave_from2.svg"
            class="lyr_tp"
            style="width: 100%"
            alt=""
          />
          <img
            src="../../assets/img/wave_from1.svg"
            style="width: 100%"
            alt=""
          />
          <div
            class="d-flex justify-center flex-column pa-5 lyr_tp"
            style="width: 100%"
          >
            <div class="d-flex justify-center mb-2">
              <img src="../../assets/img/doc_notif.svg" height="90px" alt="" />
            </div>
            <p class="white--text text-center">Pengisian Formulir</p>
          </div>
          <div
            class="closer d-flex justify-end pa-1 lyr_tp"
            style="width: 100%"
          >
            <v-btn icon small dark @click="$emit('close')">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
          <v-progress-circular
            indeterminate
            class="mr-2"
            color="#00b4cc"
          ></v-progress-circular>
          <b class="color_default">Loading...</b>
        </v-overlay>
        <div class="mt-5 px-5" v-if="!loading && data.length >= 1">
          <v-card
            class="rounded-lg pa-3 mb-3"
            v-for="(item, idx) in data"
            :key="idx"
            flat
            color="#FBFBFB"
          >
            <v-row v-if="item.tipeNotif === 'notif'">
              <v-col cols="6">
                <div class="d-flex align-center h_percent">
                  <v-icon color="#00b4cc" class="mr-2" v-if="!item.read_at"
                    >mdi-alert-decagram</v-icon
                  >
                  <p class="ma-0 pa-0 small_txt">
                    {{ item.data.title }}
                  </p>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex align-center h_percent">
                  <p class="ma-0 pa-0 small_txt color_txt">
                    {{ $date(item.data.created_at).format("D MMMM YYYY") }}
                  </p>
                </div>
              </v-col>
              <v-col cols="3">
                <v-btn
                  class="text-capitalize"
                  small
                  dark
                  depressed
                  color="#00b4cc"
                  rounded
                  @click="toRedirect(item)"
                  v-if="
                    (item.data.data &&
                      item.data.data.notif_type === 'new-user' &&
                      item.related_model.isActive === 'NonActive') ||
                    (item.data.data &&
                      item.data.data.notif_type === 'confirm-form' &&
                      item.related_model.isActive !== 2)
                  "
                >
                  {{
                    item.data.data.notif_type === "new-user"
                      ? "Aktivasi"
                      : "Konfirmasi"
                  }}
                </v-btn>
                <p
                  class="color_default"
                  v-if="
                    item.data.data &&
                    item.data.data.notif_type === 'confirm-form' &&
                    item.related_model.isActive === 2
                  "
                >
                  Telah dikonfirmas
                </p>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="6">
                <div class="d-flex align-center h_percent">
                  <!-- <v-icon color="#00b4cc" class="mr-2" v-if="!item.read_at">mdi-alert-decagram</v-icon> -->
                  <p class="ma-0 pa-0 small_txt">
                    {{ item.title }}
                  </p>
                </div>
              </v-col>
              <v-col cols="3">
                <div class="d-flex align-center h_percent">
                  <p class="ma-0 pa-0 small_txt color_txt">
                    {{ item.period }}
                  </p>
                </div>
              </v-col>
              <v-col cols="3">
                <v-btn
                  class="text-capitalize"
                  small
                  dark
                  depressed
                  color="#00b4cc"
                  @click="toRedirect(item)"
                  rounded
                >
                  Isi Formulir
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
          <div class="d-flex justify-center mt-1">
            <v-btn
              dark
              depressed
              color="#00b4cc"
              class="text-capitalize"
              v-if="data.length >= 1"
            >
              <router-link
                to="/user/notification"
                style="color: white; text-decoration: none"
              >
                Lihat selengkapnya
              </router-link>
            </v-btn>
          </div>
        </div>
        <div class="mt-5 px-5" v-if="!loading && data.length <= 0">
          <div class="d-flex justify-center mt-1">
            <b>Tidak Ada Notifikasi</b>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
// import Swal from "sweetalert2";

export default {
  name: "modalNotif",
  props: ["dialogNotif"],
  computed: {
    ...mapState({
      err: (state) => state.auth.errMsg,
      solve: (state) => state.auth.response,
      role_akun: (state) => state.role,
      token: (state) => state.token,
    }),
    filteredData() {
      // show 3 data for mobile
      return this.data.slice(0, 3);
    },
  },
  data() {
    return {
      role: "",
      loading: false,
      layerTop:
        "https://d1icd6shlvmxi6.cloudfront.net/gsc/LUD74M/54/59/46/545946cab4df4b59b8404e4723b7e175/images/beranda_3/u2032.svg?token=e1861f52e305dffa7932ec302b09b26532abb3896a5fea1e393a50cddbf1c926&pageId=742b754c-8315-4353-8f4e-f2af4ce410ec",
      layerBtm:
        "https://d1icd6shlvmxi6.cloudfront.net/gsc/LUD74M/54/59/46/545946cab4df4b59b8404e4723b7e175/images/beranda_3/u2031.svg?token=60d4a84f677ab0bf03e127b00f8a18856a9ed4d28da744c6b9fb79283a638efc&pageId=742b754c-8315-4353-8f4e-f2af4ce410ec",
      data: [],
      loading: false,
    };
  },
  mounted() {
    if (this.token) {
      this.getData();
    }
    
  },
  methods: {
    getData() {
      const date_format = "YYYY-MM-DD";
      this.loading = true;
      let body = {
        tipeNotif: "unread",
      };
      let index = 1;
      this.$store.dispatch("notifikasi/getNotifikasi", body).then((data) => {
        data.notifications.forEach((element) => {
          if (element.related_model && index <= 4) {
            element.tipeNotif = "notif";
            this.data.push(element);
            index++;
          }
        });
        data.form_fillings.forEach((element) => {
          if (index <= 5 && element.fill_status === "unfilled") {
            element.toUrl = "/formulir";
            element.tipeNotif = "form-filling";
            if (element.type === "kesling") {
              element.toUrl =
                "/formulir/kesehatan-lingkungan/redaksi/" +
                this.$date().format(date_format);
              element.title = "Pengisian Formulir Limbah Kesehatan Lingkungan";
            } else if (element.type === "kesling-key") {
              element.toUrl =
                "/formulir/pertanyaan-kunci/redaksi/" +
                this.$date().format(date_format);
              element.title =
                "Pengisian Formulir Limbah Kesehatan Lingkungan ( Pertanyaan Kunci )";
            } else if (element.type === "covid") {
              element.toUrl =
                "/formulir/covid19/redaksi/" + this.$date().format(date_format);
              element.title = "Pengisian Formulir Limbah Covid-19";
            } else if (element.type === "fasyankes") {
              element.toUrl =
                "/formulir/limbah-fasyankes/redaksi/" +
                this.$date().format(date_format);
              element.title = "Pengisian Formulir Limbah Fasyankes";
            } else {
              element.toUrl =
                "/formulir/sanitarian-kit/redaksi/" +
                this.$date().format(date_format);
              element.title = "Pengisian Formulir Limbah Sanitarian Kit";
            }
            this.data.push(element);
            index++;
          }
        });
        this.loading = false;
      });
    },
    toRedirect(item) {
      if (item.tipeNotif === "notif") {
        let body = {
          notificationId: item.id,
        };
        this.$store.dispatch("notifikasi/updateToRead", body).then((data) => {
          this.$router.push({ path: item.data.redirect_url });
        });
      } else {
        this.$router.push({ path: item.toUrl });
      }
    },
  },
};
</script>

<style scoped>
.lyr_tp {
  position: absolute;
  top: 0;
}
.more_rounded {
  border-radius: 15px !important;
}
.absolute_component {
  position: relative;
}
.opc_ {
  background: #e5f7fbd1;
}

@media screen and (max-width: 600px) {
  .opc_ {
    background: rgb(189, 189, 190, 0.7);
    backdrop-filter: blur(2px);
  }

  .transparent-card {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .btnClose {
    display: flex;
    color: white;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  
 
}
</style>