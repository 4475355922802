<template>
  <v-row justify="start">
    <v-dialog v-model="dialogTypeKesling" persistent max-width="450px">
      <v-card class="rounded-max hide_overflow" flat>
        <div class="closer d-flex justify-end pa-1">
          <v-btn icon small @click="$emit('close')">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          class="d-flex flex-column align-center justify-center"
          style="height: 300px"
        >
          <div class="d-flex justify-center">
            <button class="blue_outline mr-3" @click="$emit('pick', 'a')">Pertanyaan Kunci</button>
            <button class="blue_outline" @click="$emit('pick', 'b')">Kesehatan Lingkungan</button>
          </div>
          <div style="height: 20px"></div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alertFormulir",
  props: ["dialogTypeKesling"],

  data() {
    return {
      role: "",
      loading: false,
    };
  },
  methods: {},
};
</script>

<style scoped>
.blue_outline {
  padding: 20px;
  border: 2px solid #00b4cc;
  border-radius: 10px;
  color: #00b4cc;
  font-weight: bold;
  font-size: small;
  transition: 0.5s;
}
.blue_outline:hover {
  border: 2px solid #00b4cc;
  background: #00b4cc;
  color: #fff;
  transition: 0.5s;
}
</style>