<template>
  <div class="pa-3">
    <v-row justify="start">
      <v-dialog v-model="dialogUpdateProfile" persistent scrollable max-width="450px">
        <v-card class="rounded-max hide_overflow" flat min-height="300px">
          <div class="closer d-flex justify-end pa-1">
            <v-btn icon small @click="$emit('close')">
              <v-icon small color="#000">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text>
            <v-container class="pa-0">
              <div>
                <div class="d-flex align-center mb-5">
                  <v-icon large color="#00b4cc">mdi-calendar-month-outline</v-icon>
                  <p class="title-tanggal-modal">
                    UPDATE IDENTITAS
                  </p>
                </div>
                <div class="form-group">
                  <div v-if="identity && identity.tipe_fasyankes == '1'">
                    <v-text-field v-model="identity.kepemilikan" label="Kepemilikan" outlined></v-text-field>
                    <v-text-field v-model="identity.kelas" label="Kelas" outlined></v-text-field>
                    <v-text-field v-model="identity.persenBOR" label="BOR (%)" type="number" outlined></v-text-field>
                    <v-select v-model="identity.karakteristikWilayah" :items="region_characteristics"
                      label="Karakteristik Wilayah" outlined></v-select>
                    <v-text-field v-model="identity.jumlahTempatTidur" label="Tempat Tidur (Unit)"
                      outlined></v-text-field>
                    <v-text-field v-model="identity.timbunanLimbah" label="Timbunan Limbah" outlined></v-text-field>
                  </div>
                  <div v-else>
                    <v-text-field v-if="identity" v-model="identity.rataJumlahPasien" type="number"
                      label="Non Rawat Inap (Rata-rata jumlah pasien)" outlined></v-text-field>
                    <v-text-field v-if="identity" v-model="identity.jumlahTempatTidur" type="number"
                      label="Tempat Tidur (unit)" outlined></v-text-field>
                    <v-text-field v-if="identity" v-model="identity.persenBOR" type="number"
                      label="Rawat Inap - BOR (Bed Occupancy Rate)" outlined></v-text-field>
                    <v-select v-model="identity.karakteristikWilayah" :items="region_characteristics"
                      label="Karakteristik Wilayah" outlined></v-select>
                    <v-text-field v-if="identity" v-model="identity.timbunanLimbah" type="number" label="Timbunan Limbah"
                      outlined></v-text-field>
                  </div>
                </div>
                <div class="form-group">
                  <div class="d-flex pb-3 mt-4">
                    <!-- @click="createForm" -->
                    <v-btn @click="save" class="text-capitalize py-6" color="#00b4cc" depressed dark
                      block><b>Save</b></v-btn>
                  </div>
                </div>
              </div>
              <v-overlay :absolute="true" color="white" opacity="1" :value="loading">
                <v-progress-circular indeterminate class="mr-2" color="#00b4cc"></v-progress-circular>
                <b class="color_default">Loading...</b>
              </v-overlay>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { REGION_CHARACTERISTICS } from "../../../constants/constant";

export default {
  name: "updateIndentitas",
  props: ["dialogUpdateProfile", "identity"],
  data() {
    return {
      REGION_CHARACTERISTICS,
      loading: false,
      text: null,
      selected: null,
      listProvince: [],
      listCity: [],
      listKecamatan: [],
    };
  },
  computed: {
    region_characteristics() {
      return Object.entries(REGION_CHARACTERISTICS).map(([key, value]) => {
        return { text: value, value: value };
      });
    },
  },
  mounted() {
  },
  methods: {
    save() {
      this.loading = true;

      console.log(this.identity)

      this.$store
        .dispatch("user/updateIdentitas", this.identity)
        .then((data) => {
          this.field = 0;
          this.change = false;
          this.loading = false;
          this.Swal("success", data.message);
          this.$emit("getProfile");
          this.$emit("close");
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.Swal("error", err.message);
        });

    },
    Swal(type, msg) {
      Swal.fire({
        icon: type,
        title: msg,
        showConfirmButton: false,
        position: "top-end",
        timer: 3000,
        toast: true,
        customClass: {
          title: "family",
        },
      });
    },
  },
};
</script>

<style scoped>
.temp_day {
  width: 400px;
  display: flex;
  flex-wrap: wrap;
}

.aday {
  width: calc(100% / 7);
  cursor: pointer;
}

.bordered {
  border: 1px solid #d7d7d7 !important;
}

.bullet {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.color_txt {
  color: #7f7f7f;
}

.small_txt {
  font-size: small;
}

.temp_stepper_header {
  background: none !important;
  box-shadow: none !important;
}

.header_tabs {
  display: flex;
  justify-content: center;
  color: #00b4cc;
  background: #fff;
  width: 25%;
  border-radius: 10px 10px 0 0;
}

.active_tab {
  display: flex;
  justify-content: center;
  background: #00b4cc;
  width: 25%;
  color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 4px 7px rgb(0 180 204 / 46%) !important;
}

.number_unactive {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ececec;
  color: #5d5d5d;
  font-size: small;
  margin-right: 3px;
}

.number_active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  color: #00b4cc;
  font-size: small;
  margin-right: 3px;
}

.confirm {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #64ccb7;
  margin-right: 10px;
}

.repair {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #59b5cc;
  margin-right: 10px;
}

.sent {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d7d7d7;
  margin-right: 10px;
}

.unsend {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #f4cb08;
  margin-right: 10px;
}

.nofill {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #d92b2a;
  margin-right: 10px;
}
</style>