<template>
  <v-row>
    <v-col
      v-for="form in filtered_forms"
      :key="form.id"
      cols="12"
      style="margin-bottom: 20px"
      md="12"
    >
      <v-card :class="getCardClass(form.disabled)">
        <div
          v-if="$isMobile()"
          class="content-card-form"
          style="height: 150px"
          @click="emitEvent(form.event)"
        >
          <div class="icon-formulir-card">
            <img
              :src="form.icon"
              alt=""
            >
          </div>
          <div
            class="d-flex justify-content-between w-100"
            style="margin-right: 10px; margin-left: 20px"
          >
            <div>
              <p class="title-formulir-card-isian">
                {{ form.title }} <span class="sub-title-formulir-card-isian">{{ form.sub_title }}</span>
              </p>
            </div>
          </div>
        </div>

        <div
          v-else
          class="content-card-form"
          style="height: 174px"
        >
          <div class="icon-formulir-card">
            <img
              :src="form.icon"
              alt=""
            >
          </div>
          <div
            class="d-flex justify-content-between w-100"
            style="padding-right: 60px; padding-left: 20px"
          >
            <div>
              <p class="title-formulir-card-isian">
                {{ form.title }}
              </p>
              <p class="sub-title-formulir-card-isian">
                {{ form.sub_title }}
              </p> <!-- Sub-title element -->
            </div>
            <v-btn
              rounded
              color="#00b4cc"
              dark
              class="text-capitalize"
              @click="emitEvent(form.event)"
            >
              Isi Formulir
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>


<script>
import { FASYANKES_TYPE } from "../../../../constants/constant";

export default {
  name: "TabFormForm",
  props: {
    user_type: {
      type: String,
    },
  },
  data() {
    return {
      FASYANKES_TYPE,
      forms: [
        {
          id: 1,
          title: "Kesehatan Lingkungan Kunci",
          sub_title: "(Kesling Kunci)",
          icon: require("../../../../assets/img/icon-kesling-kunci.svg"),
          event: "showKeslingKunci",
        },
        {
          id: 2,
          title: "Kelola Limbah",
          icon: require("../../../../assets/img/icon-fasynakes-formulir.svg"),
          event: "showCreateFasyankes",
        },
        {
          id: 3,
          title: "Kesehatan Lingkungan Plus",
          sub_title: "(Kesling Plus)",
          icon: require("../../../../assets/img/icon-kesling-plus.svg"),
          event: "showKeslingPlus",
        },
        {
          id: 4,
          title: "Limbah Covid-19",
          icon: require("../../../../assets/img/icon-covid-formulir.svg"),
          event: "showLimbahCovid",
          disabled: true,
        },
        {
          id: 5,
          title: "Alkes Bermerkuri",
          icon: require("../../../../assets/img/icon-bermerkuri-formulir.svg"),
          event: "showAlkesBermerkuri",
          disabled: true,
        },
      ],
    };
  },
  computed: {
    filtered_forms() {
      // Clone the forms array to avoid mutating the original data property.
      let formsCopy = [...this.forms];

      if (this.user_type == this.FASYANKES_TYPE.PublicHealthCenter) {
        // Create the sanitarian kit form object.
        const sanitarianKitForm = {
          id: "sanitarian", // It's better to use a unique string ID if possible.
          title: "Sanitarian Kit",
          sub_title: "(SanKit)",
          icon: require("../../../../assets/img/icon-sanitarian-kit.svg"),
          event: "showSanitarianKit",
        };

        // Find the index of the Limbah Covid-19 form.
        const covidFormIndex = formsCopy.findIndex(
          (form) => form.title === "Limbah Covid-19"
        );

        if (covidFormIndex !== -1) {
          // Insert the Sanitarian Kit form before the Limbah Covid-19 form.
          formsCopy.splice(covidFormIndex, 0, sanitarianKitForm);
        } else {
          // If the Limbah Covid-19 form is not found, just add the Sanitarian Kit form to the end.
          formsCopy.push(sanitarianKitForm);
        }
      }

      return formsCopy;
    },
    
  },
  mounted() {
    
  },
  methods: {
    emitEvent(event) {
      this.$emit(event);
    },
    getCardClass(disabled) {
      return disabled
        ? "custom_card_disabled rounded-max"
        : "custom_card rounded-max";
    },
    
  },
};
</script>
<style scoped>
@media screen and (max-width: 600px){
  .title-formulir-card-isian {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    color: black;
    margin-bottom: 0 !important;
}
.icon-formulir-card {
    position: relative;
    object-fit: contain;
}

.icon-formulir-card img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    margin-left: 10px;
    margin-right: auto;
}
.sub-title-formulir-card-isian {
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 400;
    color: black;
    margin-bottom: 0;
}
}
</style>